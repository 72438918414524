import { getFormat, isGTIN, isValid } from 'gtin';

const EAN_13 = 'GTIN-13';

export const isEan13Reference = (value) => {
  try {
    return isGTIN(value) && getFormat(value) === EAN_13 && isValid(value);
  } catch {
    return false;
  }
};
