import ExportService from '@/api/exportApi';
import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({
  exportFilesPaginated: {
    items: [],
    page: 1,
    count: 0,
    has_prev: false,
    has_next: false,
  },
  merchantUids: new Set(),
});

export default {
  actions: {
    async fetchExportList({ commit }, queryParams = {}) {
      const data = await ExportService.getExportFilesList(queryParams);
      commit('setExportList', { data });
    },
    async resetSelectedMerchants({ commit }) {
      commit('resetExportMerchantUids');
    },
  },
  getters: {
    getExportMerchantUids: (state) => state.merchantUids,
    isMerchantUidSelectedForExport: (state) => (merchantUid) => state.merchantUids.has(merchantUid),
    // Export Files table
    getExportFilesItems: (state) => state.exportFilesPaginated.items,
    getExportFilesCount: (state) => state.exportFilesPaginated.count,
    getExportFilesHasPrev: (state) => state.exportFilesPaginated.has_prev,
    getExportFilesHasNext: (state) => state.exportFilesPaginated.has_next,
    getExportFilesPage: (state) => state.exportFilesPaginated.page,
  },
  mutations: {
    setExportList(state, { data }) {
      state.exportFilesPaginated = data;
    },
    toggleExportMerchantUid(state, merchantUid) {
      if (state.merchantUids.has(merchantUid)) {
        // Remove from set
        state.merchantUids.delete(merchantUid);
      } else {
        // add to set
        state.merchantUids.add(merchantUid);
      }
      // Important for reactivity
      state.merchantUids = new Set(state.merchantUids);
    },
    resetExportMerchantUids(state) {
      state.merchantUids = new Set();
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
  state: getDefaultState(),
};
