import httpService from './httpService';

const ENDPOINTS = {
  fetch_transfer: (merchantUid, transferUid) =>
    `/users/me/merchants/${merchantUid}/transfers/${transferUid}`,
  fetch_transfers: '/users/me/merchants/transfers',
  fetch_debit_order: (merchantUid, debitOrderUid) =>
    `/users/me/merchants/${merchantUid}/debits/${debitOrderUid}`,
};

async function fetchTransfers(merchantUids, limit, page, filters, searchQuery) {
  const params = {
    merchant_uids: JSON.stringify(merchantUids),
    limit,
    page,
    ...searchQuery,
    ...Object.fromEntries(filters),
  };
  return httpService.get(ENDPOINTS.fetch_transfers, params);
}

async function fetchTransfer(merchantUid, transferUid) {
  return httpService.get(ENDPOINTS.fetch_transfer(merchantUid, transferUid));
}

async function fetchDebitOrder(merchantUid, debitOrderUid) {
  return httpService.get(ENDPOINTS.fetch_debit_order(merchantUid, debitOrderUid));
}

const TransferService = {
  fetchTransfer,
  fetchTransfers,
  fetchDebitOrder,
};

export default TransferService;
