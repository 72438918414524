import { createAuth } from '@websanova/vue-auth';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';

let authInstance;

function initializeAuth(app, router, http) {
  authInstance = createAuth({
    plugins: {
      http,
      router,
    },
    drivers: {
      auth: {
        request(_req, _token) {},
        response(_res) {
          return true;
        },
      },
      http: driverHttpAxios,
      router: driverRouterVueRouter,
    },
    options: {
      stores: ['cookie', 'storage'],
      loginData: {
        url: 'users/login',
        redirect: '/dashboard',
      },
      logoutData: {
        url: 'users/logout',
        makeRequest: true,
        redirect: '/dashboard',
      },
      fetchData: {
        url: 'users/me',
      },
      refreshData: {
        enabled: false,
      },
      parseUserData(data) {
        return data;
      },
    },
  });
  app.use(authInstance);
}

export { initializeAuth, authInstance };
