import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({ purchases: [] });

const findPurchaseIndex = (state, uid) =>
  state.purchases.findIndex((purchase) => purchase.uid === uid);

export default {
  getters: {
    getPurchase: (state) => (uid) => state.purchases.find((purchase) => purchase.uid === uid),
    purchases: (state) => state.purchases,
  },
  mutations: {
    setEssentielMerchantPaid(state, { purchase_uid }) {
      const index = findPurchaseIndex(state, purchase_uid);
      if (index !== -1) {
        state.purchases[index].is_essentiel_merchant_paid = true;
        state.purchases[index].is_eligible_for_essentiel_merchant_payment = false;
        state.purchases[index].is_eligible_for_essentiel_merchant_release = false;
      }
    },
    setEssentielReleased(state, { purchase_uid }) {
      const index = findPurchaseIndex(state, purchase_uid);
      if (index !== -1) {
        state.purchases[index].is_essentiel_released = true;
        state.purchases[index].is_eligible_for_essentiel_merchant_payment = false;
        state.purchases[index].is_eligible_for_essentiel_merchant_release = false;
      }
    },
    setPurchases(state, purchases) {
      state.purchases = purchases;
    },
    updatePurchase(state, purchase) {
      const purchaseIndex = findPurchaseIndex(state, purchase.uid);
      if (purchaseIndex !== -1) {
        state.purchases.splice(purchaseIndex, 1, purchase);
      } else {
        state.purchases.push(purchase);
      }
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
  state: getDefaultState(),
};
