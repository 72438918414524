import { ElNotification } from 'element-plus';
import axios from 'axios';
import { VUE_APP_ECARD_BACK_API_SUFFIX, VUE_APP_ECARD_BACK_URL, VUE_APP_VERSION } from '@/config';
import i18n from '@/plugins/i18n';
import { updateServiceWorker } from '@/service-worker-helper';
import { ERRORS } from '../constants';
import { Logger } from '../utils/logger';
import AppContext from '../AppContext';

const defaultServiceOptions = {
  redirectOn403: true,
  returnFullResponse: false,
};

const httpHandler =
  (method) =>
  async (
    url,
    params,
    data,
    options,
    serviceOptions = defaultServiceOptions,
    responseType = null,
  ) => {
    try {
      const { $http } = AppContext.globalProperties;
      const response = await $http.request({
        url,
        method,
        params,
        data,
        options,
        responseType,
      });
      return serviceOptions.returnFullResponse ? response : response.data;
    } catch (err) {
      if (err.response?.status === 403 && serviceOptions.redirectOn403) {
        ElNotification({
          type: 'error',
          title: i18n.global.t('errors.resource_not_allowed'),
        });
        throw new Error(ERRORS.RESOURCE_NOT_ALLOWED);
      }
      if (err.response?.status === 404) {
        throw new Error(ERRORS.RESOURCE_NOT_FOUND);
      }
      // Do not send a new Error since it breaks the object contained in error
      // eslint-disable-next-line no-throw-literal
      throw err.response?.data?.error || err.message;
    }
  };

function initializeHttp() {
  const baseUrl = VUE_APP_ECARD_BACK_URL + VUE_APP_ECARD_BACK_API_SUFFIX;

  Logger.info({ id: 'httpService.initialize', baseUrl });
  let http;

  // https://github.com/axios/axios/blob/main/lib/defaults/index.js
  const axiosParameters = {
    baseURL: baseUrl,
    withCredentials: true,
  };
  http = axios.create(axiosParameters);
  return { http, baseUrl };
}

function getHeaders() {
  return {
    'm-dashboard-version': VUE_APP_VERSION,
  };
}

function getFilename(response) {
  const regex = /filename="(.*)"/;
  if (response && response.headers['content-disposition']) {
    const match = response.headers['content-disposition'].match(regex);
    return match ? match[1] : null;
  }
  return null;
}

function initialize() {
  const { http, baseUrl } = initializeHttp();

  http.defaults.headers.common = {
    ...http.defaults.headers.common,
    ...getHeaders(),
  };

  http.interceptors.request.use(
    (request) => {
      Logger.info({
        id: 'axios-request',
        method: request?.method,
        url: request?.url,
        request,
      });
      // Ensure the user uses the last app version
      updateServiceWorker();
      return request;
    },
    (error) => {
      Logger.error({
        id: 'axios-request-error',
        method: error?.config?.method,
        url: error?.config?.url,
        error,
      });
    },
  );
  http.interceptors.response.use(
    (response) => {
      Logger.info({
        id: 'axios-response',
        method: response?.config?.method,
        url: response?.config?.url,
        response,
      });
      return response;
    },
    (error) => {
      Logger.error({
        id: 'axios-response-error',
        method: error?.config?.method,
        url: error?.config?.url,
        error,
      });
      throw error;
    },
  );

  return { http, baseUrl };
}

export default {
  initialize,
  initializeHttp,
  getHeaders,
  getFilename,
  get: httpHandler('get'),
  post: httpHandler('post'),
  patch: httpHandler('patch'),
  put: httpHandler('put'),
  delete: httpHandler('delete'),
};
