export const getMessage = (message) => {
  if (Array.isArray(message)) return message.join(' | ');
  if (message instanceof Error) return message.message;
  if (typeof message === 'object') return JSON.stringify(message, null, 1);

  const returnMessage = message || '';

  return `${returnMessage}`;
};

export const detectNode =
  Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) ===
    '[object process]' || process.env.APPLICATION_ENV === 'production';

/**
 * Apply style on log message in browser only (i.e. detect node process)
 * https://developer.mozilla.org/fr/docs/Web/API/Console
 * @param {*} name tag to apply on the log message
 * @param {*} style to apply on the log message (font style, background style)
 * @returns the pattern to apply on the final log message
 */
export const mountLog = (name, style) => {
  if (detectNode) {
    return [`${name}: `];
  }

  return [`%c${name}%c:`, style, 'background: transparent;'];
};
