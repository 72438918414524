/**
 * Download data as CSV file
 * @param {*} data from response
 * @param {*} name the name to give to the file
 */
function downloadFile(data, name, type) {
  const blob = new Blob([data], { type });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name;
  link.click();
  URL.revokeObjectURL(link.href);
}

export function downloadCSV(data, csvName = 'file.csv') {
  return downloadFile(data, csvName, 'text/csv');
}

export function downloadPDF(data, pdfName = 'file.pdf') {
  return downloadFile(data, pdfName, 'application/pdf');
}
