import { ElNotification } from 'element-plus';
import i18n from '@/plugins/i18n';
import MerchantService from '@/api/merchants';
import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({
  merchants: [],
  isFetchingMerchants: false,
  isFetchingMerchant: false,
  paymentTypes: [],
  isSubmittingMerchant: false,
  submitErrorMerchant: null,
  selectedMerchants: [],
  selectMultiple: false,
});

const getMerchantFromUid = (state) => (uid) =>
  state.merchants.find((merchant) => merchant.uid === uid);

const getSelectedMerchantUid = (state) => state.selectedMerchants[0];

const getSelectedMerchant = (state) => {
  if (!state.selectedMerchants.length) return undefined;
  const merchantUid = getSelectedMerchantUid(state);
  return getMerchantFromUid(state)(merchantUid);
};

export default {
  actions: {
    async fetchMerchants({ commit }) {
      commit('setIsFetchingMerchants', { isFetchingMerchants: true });
      try {
        const data = await MerchantService.fetchMerchants();
        commit('setMerchants', { merchants: data });
        return data;
      } finally {
        commit('setIsFetchingMerchants', { isFetchingMerchants: false });
      }
    },
    async fetchMerchant({ commit }, { merchantUid }) {
      commit('setIsFetchingMerchant', { isFetchingMerchant: true });
      const data = await MerchantService.fetchMerchant(merchantUid);
      commit('setIsFetchingMerchant', { isFetchingMerchant: false });
      commit('updateMerchant', { merchant: data });
      return data;
    },
    async createMerchant({ commit }, { formData }) {
      commit('isSubmittingMerchant', { isSubmittingMerchant: true });
      commit('resetSubmitErrorMerchant');
      let data;
      try {
        data = await MerchantService.createMerchant(formData);
        ElNotification({
          type: 'success',
          title: i18n.global.t('merchant_form.create_successful'),
        });
      } catch (error) {
        commit('setSubmitError', { error });
      } finally {
        commit('isSubmittingMerchant', { isSubmittingMerchant: false });
      }
      return data;
    },
    async updateMerchant({ commit }, { merchantUid, formData }) {
      commit('isSubmittingMerchant', { isSubmittingMerchant: true });
      commit('resetSubmitErrorMerchant');
      let data;
      try {
        data = await MerchantService.updateMerchant(merchantUid, formData);
        ElNotification({
          type: 'success',
          title: i18n.global.t('merchant_form.update_successful'),
        });
      } catch (error) {
        commit('setSubmitError', { error });
      } finally {
        commit('isSubmittingMerchant', { isSubmittingMerchant: false });
      }
      return data;
    },
    async fetchPaymentTypes({ commit }) {
      const paymentTypes = await MerchantService.fetchPaymentTypes();
      commit('setPaymentTypes', { paymentTypes });
    },
    setSelectedMerchants({ commit }, { selectedMerchants }) {
      commit('setSelectedMerchants', { selectedMerchants });
    },
    setSelectMultiple({ commit }, { selectMultiple }) {
      commit('setSelectMultiple', { selectMultiple });
    },
    toggleMerchant({ commit, state }, { merchantUid }) {
      if (state.selectMultiple) {
        commit('toggleSelectedMultipleMerchant', { merchantUid });
      } else {
        commit('setSelectedMerchant', { merchantUid });
      }
    },
    unselectAll({ commit }) {
      commit('setSelectedMerchants', { selectedMerchants: [] });
    },
  },
  getters: {
    merchants: (state) => state.merchants,
    firstMerchant: (state) => state.merchants.length && state.merchants[0],
    isFetchingMerchant: (state) => state.isFetchingMerchant,
    isFetchingMerchants: (state) => state.isFetchingMerchants,
    getMerchant: getMerchantFromUid,
    paymentTypes: (state) => state.paymentTypes,
    purchaseLinkSettings: (state) => {
      const merchant = getSelectedMerchant(state);
      return merchant?.merchant_dashboard_settings?.purchase_link;
    },
    isSubmittingMerchant: (state) => state.isSubmittingMerchant,
    submitErrorMerchant: (state) => state.submitErrorMerchant,
    selectedMerchants: (state) => state.selectedMerchants || [],
    selectedMerchantUids: (state) => {
      return state.merchants
        .map((merchant) => {
          if (state.selectedMerchants.includes(merchant.uid)) return merchant.uid;
        })
        .filter((m) => m);
    },
    selectedMerchant: getSelectedMerchant,
    selectedMerchantUid: getSelectedMerchantUid,
    selectMultiple: (state) => state.selectMultiple,
  },
  mutations: {
    setMerchants(state, { merchants }) {
      state.merchants = merchants.map((merchant) => ({
        ...state.merchants.find((m) => m.uid === merchant.uid),
        ...merchant,
      }));
    },
    updateMerchant(state, { merchant }) {
      const merchantIndex = state.merchants.findIndex((m) => m.uid === merchant.uid);
      if (merchantIndex !== -1) {
        state.merchants.splice(merchantIndex, 1, merchant);
      } else {
        state.merchants = [...state.merchants, merchant];
      }
    },
    setIsFetchingMerchant(state, { isFetchingMerchant }) {
      state.isFetchingMerchant = isFetchingMerchant;
    },
    setIsFetchingMerchants(state, { isFetchingMerchants }) {
      state.isFetchingMerchants = isFetchingMerchants;
    },
    setPaymentTypes(state, { paymentTypes }) {
      state.paymentTypes = paymentTypes;
    },
    setSubmitError(state, { error }) {
      state.submitErrorMerchant = error;
    },
    resetSubmitErrorMerchant(state) {
      state.submitErrorMerchant = null;
    },
    isSubmittingMerchant(state, { isSubmittingMerchant }) {
      state.isSubmittingMerchant = isSubmittingMerchant;
    },

    /* Selection. */
    setSelectedMerchants(state, { selectedMerchants }) {
      state.selectedMerchants = [...selectedMerchants];
    },
    setSelectMultiple(state, { selectMultiple }) {
      state.selectMultiple = selectMultiple;
    },
    setSelectedMerchant(state, { merchantUid }) {
      state.selectedMerchants = [merchantUid];
    },
    toggleSelectedMultipleMerchant(state, { merchantUid }) {
      const idx = state.selectedMerchants.indexOf(merchantUid);
      const selectedMerchants = [...state.selectedMerchants];

      if (idx > -1) {
        selectedMerchants.splice(idx, 1);
      } else {
        selectedMerchants.push(merchantUid);
      }
      state.selectedMerchants = selectedMerchants;
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
  state: getDefaultState(),
};
