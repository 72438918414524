import httpService from './httpService';

const ENDPOINTS = {
  keyIndicators: '/users/me/key_indicators',
  settings: '/users/me/settings',
  collaboratorsSettings: '/users/me/settings/collaborators',
};

/**
 * Returns the metabase iframe urls to display
 */
async function getKeyIndicators() {
  return httpService.get(ENDPOINTS.keyIndicators);
}

async function fetchUserSettings() {
  return httpService.get(ENDPOINTS.settings);
}

async function updateCollaborators(formData) {
  return httpService.post(ENDPOINTS.collaboratorsSettings, null, formData);
}

const UserService = {
  getKeyIndicators,
  fetchUserSettings,
  updateCollaborators,
};

export default UserService;
