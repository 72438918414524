import { downloadPDF } from '@/helpers/downloadFile';
import httpService from './httpService';

const ENDPOINTS = {
  fetch_documents: (companyUid) => `/users/me/company/${companyUid}/documents`,
  download_document: (companyUid, documentUid) =>
    `/users/me/company/${companyUid}/documents/${documentUid}/download`,
};

async function fetchDocuments(companyUid, limit, page) {
  const params = {
    limit,
    page,
  };
  return httpService.get(ENDPOINTS.fetch_documents(companyUid), params);
}

async function downloadDocument(companyUid, documentUid) {
  const response = await httpService.get(
    ENDPOINTS.download_document(companyUid, documentUid),
    null,
    null,
    null,
    {
      redirectOn403: true,
      returnFullResponse: true,
    },
    'blob',
  );
  const filename = httpService.getFilename(response);
  downloadPDF(response.data, filename);
}

const DocumentService = {
  fetchDocuments,
  downloadDocument,
};

export default DocumentService;
