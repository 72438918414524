import { VUE_APP_ZENDESK_KEY } from '@/config';

function permissionsMixin() {
  return {
    computed: {
      permissionsMixin_isChatAllowed() {
        return !!this.$auth.user()?.chat_allowed;
      },
      permissionsMixin_hasOrders() {
        return !!this.$auth.user()?.has_orders;
      },
      permissionsMixin_userInfos() {
        return {
          chat_allowed: this.permissionsMixin_isChatAllowed,
          active: this.$auth.user()?.active,
          email: this.$auth.user()?.email,
          uid: this.$auth.user()?.uid,
        };
      },
    },
    methods: {
      permissionsMixin_addZendeskChat() {
        if (!this.permissionsMixin_isChatAllowed || !VUE_APP_ZENDESK_KEY) return;

        // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
        const zenscript = document.createElement('script');
        zenscript.setAttribute('id', 'ze-snippet');
        zenscript.setAttribute('defer', '');
        zenscript.setAttribute(
          'src',
          `https://static.zdassets.com/ekr/snippet.js?key=${VUE_APP_ZENDESK_KEY}`,
        );
        document.head.appendChild(zenscript);
        setTimeout(() => {
          if (typeof zE !== 'undefined') {
            zE('messenger:set', 'locale', this.$i18n.locale);
          }
        }, 1000);
      },
      permissionsMixin_isAuthorized(authorization) {
        if (!authorization) return true;
        return !!this.$auth?.user()?.authorizations.find((auth) => auth === authorization);
      },
    },
  };
}

export default permissionsMixin();
