import LOCALES from './locales';

const STATES_TRANSLATIONS = {
  [LOCALES.FR]: {
    ACCEPTED: 'Accepté',
    BANK_REFUSED: 'Refus banque client',
    CANCELLED: 'Annulé',
    CLOSED: 'Clôturé',
    DEFAULTED: 'En défaut',
    ERROR: 'Erreur',
    IN_PROGRESS: 'En cours de traitement',
    INITIATED: 'Initié',
    INSURANCE_REFUSED: 'Refus assureur',
    NOT_FINALIZED: 'Non finalisé',
    NOT_SENT: 'Non envoyé',
    READY: 'Prêt',
    RECEIVED: 'Reçu',
    REDIRECTION_DONE: 'Redirection faite',
    REDIRECTION_ERROR: 'Redirection erreur',
    REFUSED: 'Refusé',
    REJECTED: 'Rejeté',
    SENT: 'Envoyé',
    WAITING: 'Action client',
    PAID: 'Payé',
    UNPAID: 'Non payé',
  },
  [LOCALES.EN]: {
    ACCEPTED: 'Accepted',
    BANK_REFUSED: 'Customer bank refusal',
    CANCELLED: 'Canceled',
    CLOSED: 'Closed',
    DEFAULTED: 'Defaulted',
    ERROR: 'Error',
    IN_PROGRESS: 'In progress',
    INITIATED: 'Initiated',
    INSURANCE_REFUSED: 'Insurer refusal',
    NOT_FINALIZED: 'Not finalized',
    NOT_SENT: 'Not sent',
    READY: 'Ready',
    RECEIVED: 'Received',
    REDIRECTION_DONE: 'Redirection done',
    REDIRECTION_ERROR: 'Redirection error',
    REFUSED: 'Refused',
    REJECTED: 'Rejected',
    SENT: 'Sent',
    WAITING: 'Awaiting customer',
    PAID: 'Paid',
    UNPAID: 'Unpaid',
  },
};

export default function (locale) {
  const STATES = STATES_TRANSLATIONS[locale];
  return {
    order: {
      INITIATED: STATES.INITIATED,
      CLOSED: STATES.CLOSED,
      STARTED: STATES.INITIATED,
      WAITING_CONFIRMATION: STATES.IN_PROGRESS,
      PREAUTHORIZATION_KO: STATES.REFUSED,
      PREAUTHORIZATION_OK: STATES.IN_PROGRESS,
      DUPLICATED_KO: STATES.ERROR,
      SCORING_KO: STATES.REFUSED,
      ACCEPTED: STATES.ACCEPTED,
    },
    transfer: {
      PENDING: STATES.IN_PROGRESS,
      WAITING_SELENIUM_NOTIFICATION: STATES.IN_PROGRESS,
      SENT: STATES.SENT,
      NOT_SENT: STATES.NOT_SENT,
      PROCESSING_ERROR: STATES.ERROR,
      RECEIVED: STATES.RECEIVED,
      CREATED: STATES.IN_PROGRESS,
      OK: STATES.SENT,
      KO: STATES.ERROR,
      ABORTED: STATES.ERROR,
    },
    export: {
      CREATED: STATES.IN_PROGRESS,
      RETRIEVING: STATES.IN_PROGRESS,
      ERROR: STATES.ERROR,
      SENDING: STATES.IN_PROGRESS,
      SENT: STATES.READY,
    },
    invoice: {
      PAID: STATES.PAID,
      UNPAID: STATES.UNPAID,
    },
  };
}
