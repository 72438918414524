import CollaboratorService from '@/api/collaborator';
import i18n from '@/plugins/i18n';
import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({ collaborators: [], error: null });

export default {
  actions: {
    async fetchCollaborators({ commit }, { merchantUid }) {
      const collaborators = await CollaboratorService.fetchCollaborators(merchantUid);
      commit('setCollaborators', { collaborators });
    },
    async addCollaborator({ commit, dispatch }, { merchantUid, email }) {
      commit('resetError');
      try {
        await CollaboratorService.createCollaborator(merchantUid, email);
        dispatch('fetchCollaborators', { merchantUid });
      } catch (error) {
        commit('setError', { error, defaultMessageKey: 'collaborators.error_add_default' });
      }
    },
    async deleteCollaborator({ dispatch, commit }, { merchantUid, collaboratorUid }) {
      commit('resetError');
      try {
        await CollaboratorService.deleteCollaborator(merchantUid, collaboratorUid);
        dispatch('fetchCollaborators', { merchantUid });
      } catch (error) {
        commit('setError', { error, defaultMessageKey: 'collaborators.error_delete_default' });
      }
    },
    async clearCollaborators({ commit }) {
      commit('setCollaborators', { collaborators: [] });
      commit('resetError');
    },
  },
  getters: {
    getCollaborators: (state) => state.collaborators,
    error: (state) => state.error,
  },
  mutations: {
    setCollaborators(state, { collaborators }) {
      state.collaborators = collaborators || [];
    },
    setError(state, { error, defaultMessageKey }) {
      const defaultErrorMessage = i18n.global.t(defaultMessageKey);
      const messageKey = `collaborators.error_${error.info}`;
      state.error = i18n.global.te(messageKey) ? i18n.global.t(messageKey) : defaultErrorMessage;
    },
    resetError(state) {
      state.error = null;
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
  state: getDefaultState(),
};
