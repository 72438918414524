import { ENTITY_TYPE, DefaultFilters } from '../../constants';
import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => {
  const defaultState = {};
  Object.keys(ENTITY_TYPE).forEach((entityType) => {
    defaultState[entityType] = DefaultFilters[entityType] || {
      categories: [],
      queryParameters: [],
    };
  });
  return defaultState;
};

export default {
  actions: {
    saveFilters({ commit }, { entityType, filters }) {
      commit('setFiltersForEntity', { entityType, filters });
    },
  },
  getters: {
    purchaseActiveCategories: (state) => state[ENTITY_TYPE.PURCHASE].categories,
    purchaseQueryParameters: (state) => state[ENTITY_TYPE.PURCHASE].queryParameters,
    orderActiveCategories: (state) => state[ENTITY_TYPE.ORDER].categories,
    orderQueryParameters: (state) => state[ENTITY_TYPE.ORDER].queryParameters,
    transferActiveCategories: (state) => state[ENTITY_TYPE.TRANSFER].categories,
    transferQueryParameters: (state) => state[ENTITY_TYPE.TRANSFER].queryParameters,
    getFiltersForEntity: (state) => (entityType) => state[entityType],
  },
  mutations: {
    setFiltersForEntity(state, { entityType, filters }) {
      Object.assign(state, { ...state, [entityType]: filters });
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
  state: getDefaultState(),
};
