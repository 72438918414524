import { ElNotification } from 'element-plus';
import TransferService from '@/api/transfers';
import { ERRORS, TransferTypes } from '@/constants';
import i18n from '@/plugins/i18n';
import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({
  transfers: [],
  isFetchingTransfer: false,
});

export default {
  actions: {
    updateTransfer({ commit }, { transfer }) {
      commit('updateTransfer', transfer);
    },
    async fetchTransfer({ commit }, { merchantUid, transferUid, transferType, router }) {
      commit('setIsFetchingTransfer', { isFetchingTransfer: true });
      try {
        const data =
          transferType === TransferTypes.TRANSFER
            ? await TransferService.fetchTransfer(merchantUid, transferUid)
            : await TransferService.fetchDebitOrder(merchantUid, transferUid);
        if (data) {
          commit('updateTransfer', data);
        }
      } catch (e) {
        if (e.message === ERRORS.RESOURCE_NOT_FOUND) {
          ElNotification({ type: 'error', title: i18n.global.t('errors.resource_not_found') });
          router.push({ name: 'merchant-transfers' });
          return;
        }
      } finally {
        commit('setIsFetchingTransfer', { isFetchingTransfer: false });
      }
    },
  },
  getters: {
    getTransfer: (state) => (uid) => state.transfers.find((transfer) => transfer.uid === uid),
    isFetchingTransfer: (state) => state.isFetchingTransfer,
    transfers: (state) => state.transfers,
  },
  mutations: {
    setTransfers(state, transfers) {
      state.transfers = transfers;
    },
    updateTransfer(state, transfer) {
      const transferIndex = state.transfers.findIndex((t) => t.uid === transfer.uid);
      if (transferIndex !== -1) {
        state.transfers.splice(transferIndex, 1, transfer);
      } else {
        state.transfers.push(transfer);
      }
    },
    setIsFetchingTransfer(state, { isFetchingTransfer }) {
      state.isFetchingTransfer = isFetchingTransfer;
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
  state: getDefaultState(),
};
