import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({ orders: [] });

export default {
  getters: {
    getOrder: (state) => (uid) => state.orders.find((order) => order.uid === uid),
    orders: (state) => state.orders,
  },
  mutations: {
    setOrders(state, orders) {
      state.orders = orders;
    },
    updateOrder(state, order) {
      const orderIndex = state.orders.findIndex((o) => o.uid === order.uid);
      if (orderIndex === -1) {
        state.orders.push(order);
      } else {
        state.orders.splice(orderIndex, 1, order);
      }
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
  state: getDefaultState(),
};
