import CompanyService from '@/api/companies';

export const getDefaultState = () => ({
  paginatedCompanies: {
    items: [],
    page: 1,
    count: 0,
    has_prev: false,
    has_next: false,
  },
  company: undefined,
  isFetchingCompanies: false,
  isFetchingCompany: false,
  selectedCompanyUid: undefined,
});

export default {
  actions: {
    async fetchCompanies({ commit }, queryParams = {}) {
      commit('setIsFetchingCompanies', { isFetchingCompanies: true });
      try {
        const data = await CompanyService.fetchCompanies(queryParams);
        commit('setPaginatedCompanies', { data });
        return data;
      } finally {
        commit('setIsFetchingCompanies', { isFetchingCompanies: false });
      }
    },
    async fetchCompany({ commit }, { companyUid }) {
      commit('setIsFetchingCompany', { isFetchingCompany: true });
      const data = await CompanyService.fetchCompany(companyUid);
      commit('setIsFetchingCompany', { isFetchingCompany: false });
      commit('setSelectedCompany', { company: data });
      return data;
    },
    setSelectedCompanyUid({ commit }, { companyUid }) {
      commit('setSelectedCompanyUid', { companyUid });
    },
  },
  getters: {
    selectedCompanyUid: (state) => state.selectedCompanyUid,
    company: (state) => state.company,
    isFetchingCompany: (state) => state.isFetchingCompany,
    isFetchingCompanies: (state) => state.isFetchingCompanies,

    // Companies paginated result
    companies: (state) => state.paginatedCompanies.items,
    companiesCount: (state) => state.paginatedCompanies.count,
    getCompaniesHasPrev: (state) => state.paginatedCompanies.has_prev,
    getCompaniesHasNext: (state) => state.paginatedCompanies.has_next,
    getCompaniesPage: (state) => state.paginatedCompanies.page,
  },
  mutations: {
    setPaginatedCompanies(state, { data }) {
      state.paginatedCompanies = data;
    },
    setSelectedCompany(state, { company }) {
      state.company = company;
    },
    setIsFetchingCompanies(state, { isFetchingCompanies }) {
      state.isFetchingCompanies = isFetchingCompanies;
    },
    setIsFetchingCompany(state, { isFetchingCompany }) {
      state.isFetchingCompany = isFetchingCompany;
    },
    /* Selection. */
    setSelectedCompanyUid(state, { companyUid }) {
      state.selectedCompanyUid = companyUid;
    },
  },
  state: getDefaultState(),
};
