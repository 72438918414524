import httpService from './httpService';

const ENDPOINTS = {
  collaborators: (merchantUid) => `/users/me/merchant/${merchantUid}/collaborators`,
  collaborator: (merchantUid, collaboratorUid) =>
    `/users/me/merchant/${merchantUid}/collaborators/${collaboratorUid}`,
};

/**
 * Associate a new collaborator to the current payment solution
 * Currently a 403 can be returned in case of error (ForbiddenError)
 * when a user is already linked to a role
 *
 * @param {*} merchantUid - The ID of the payment solution (i.e. merchant)
 * @param {*} email - The email of the collaborator to add
 * @returns
 */
async function createCollaborator(merchantUid, email) {
  return httpService.post(ENDPOINTS.collaborators(merchantUid), null, { email }, null, {
    redirectOn403: false,
  });
}

async function fetchCollaborators(merchantUid) {
  return httpService.get(ENDPOINTS.collaborators(merchantUid));
}

async function deleteCollaborator(merchantUid, collaboratorUid) {
  return httpService.delete(
    ENDPOINTS.collaborator(merchantUid, collaboratorUid),
    null,
    null,
    null,
    {
      redirectOn403: false,
    },
  );
}

const CollaboratorService = {
  createCollaborator,
  fetchCollaborators,
  deleteCollaborator,
};

export default CollaboratorService;
