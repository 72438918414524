import Sentry from '@/plugins/sentry';
import { Logger } from '@/utils/logger';

const { SENTRY_KEYS, SENTRY_LEVELS, captureMessage } = Sentry;

const initGlobalMixin = (app) => {
  app.mixin({
    created() {
      Logger.debug({
        id: `vue-mixin-created-${this.$vnode?.tag}`,
        data: this.$data,
        props: this.$props,
      });
    },
    methods: {
      /**
       * Notifications
       */
      notify(type, title, message, duration = 5000) {
        if (!title) return;
        this.$notify({ type, title, message, duration });
      },
      notifyUnknownError() {
        this.notify('error', this.$t('errors.unknown'), this.$t('errors.try_again'));
      },
      notifyInfo(title, text) {
        this.notify('info', title, text);
      },
      notifyWarning(title, text) {
        this.notify('warn', title, text);
      },
      notifyError(title, text) {
        this.notify('error', title, text, 10000);
      },
      notifySuccess(title, text) {
        this.notify('success', title, text);
      },
      /**
       * I18n: default message if key does not exist
       * https://kazupon.github.io/vue-i18n/api/#te
       */
      $td(messageKey, fallbackStr) {
        if (this.$te(messageKey)) return this.$t(messageKey);

        captureMessage(SENTRY_KEYS.MISSING_I18N_KEY, SENTRY_LEVELS.WARNING, {
          key: messageKey,
          route: this.$route.path,
        });

        if (fallbackStr) return fallbackStr;
        return messageKey;
      },
    },
  });
};

export default initGlobalMixin;
