import { Logger } from '@/utils/logger';

function reloadMixin() {
  return {
    data() {
      return {
        swRefreshing: false,
      };
    },
    created() {
      if (!navigator.serviceWorker) return;

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.swRefreshing) return;
        Logger.info('SW controller change - reload window');
        this.swRefreshing = true;
        window.location.reload();
      });
    },
  };
}

export default reloadMixin();
