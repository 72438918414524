const initDirectives = (app) => {
  app.directive('short-click', {
    beforeMount(el, binding) {
      // Default short-click threshold is 300ms
      let timeout = parseInt(binding.arg) || 300;
      let clickStartTime = 0;

      el.addEventListener('mousedown', () => {
        clickStartTime = Date.now();
      });

      el.addEventListener('click', (event) => {
        // Prevent the default click behavior
        event.preventDefault();

        const clickDuration = Date.now() - clickStartTime;

        if (clickDuration <= timeout) {
          // Trigger the function passed as the directive's value
          // if the click duration is within the threshold
          binding.value(event);
        }
      });
    },
  });

  app.directive('indeterminate', (el, binding) => {
    el.indeterminate = Boolean(binding.value);
  });

  app.directive('click-outside', {
    mounted(el, binding) {
      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent);
    },
  });
};

export default initDirectives;
