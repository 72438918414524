import { library } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

import {
  faChartLine,
  faCheck,
  faCopy,
  faGlobe,
  faPen,
  faEuroSign,
  faSpinner,
  faPlus,
  faSearch,
  faFileInvoiceDollar,
  faFilter,
  faDollarSign,
  faEllipsisH,
  faLongArrowAltLeft,
  faDownload,
  faFile,
  faEye,
  faEyeSlash,
  faLink,
  faAngleDown,
  faAngleUp,
  faUserPlus,
  faTrashAlt,
  faSyncAlt,
  faExternalLinkAlt,
  faSignOutAlt,
  faTimes,
  faArrowCircleLeft,
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faClock,
  faCheckCircle,
  faTimesCircle,
  faReply,
  faReplyAll,
  faQuestionCircle,
  faExclamation,
  faTrash,
  faFilePdf,
  faCog,
} from '@fortawesome/free-solid-svg-icons';

import { faCalendar, faEdit, faListAlt, faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons';

library.add(
  faChartLine,
  faCheck,
  faEdit,
  faPen,
  faCopy,
  faCog,
  faGlobe,
  faSpinner,
  faPlus,
  faSearch,
  faFileInvoiceDollar,
  faFilter,
  faCalendar,
  faEuroSign,
  faDollarSign,
  faEllipsisH,
  faLongArrowAltLeft,
  faDownload,
  faFile,
  faEye,
  faEyeSlash,
  faLink,
  faMoneyBillAlt,
  faAngleUp,
  faAngleDown,
  faUserPlus,
  faTrashAlt,
  faSyncAlt,
  faExternalLinkAlt,
  faSignOutAlt,
  faTimes,
  faArrowCircleLeft,
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faClock,
  faCheckCircle,
  faTimesCircle,
  faReply,
  faReplyAll,
  faListAlt,
  faQuestionCircle,
  faExclamation,
  faTrash,
  faFilePdf,
);

const initFontAwesome = (app) => {
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.component('font-awesome-layers', FontAwesomeLayers);
  app.component('font-awesome-layers-text', FontAwesomeLayersText);
};

export default initFontAwesome;
