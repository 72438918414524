import httpService from './httpService';

export const ENDPOINTS = {
  companies: '/users/me/companies',
  company: (companyUid) => `/users/me/companies/${companyUid}`,
  companySecret: (companyUid) => `/users/me/companies/${companyUid}/secret`,
  companySecretActivation: (companyUid) => `/users/me/companies/${companyUid}/secret/activate`,
};

async function fetchCompanies({ limit, page, companyName }) {
  const params = {
    limit,
    page,
  };
  if (companyName) {
    params['company_name'] = companyName;
  }
  return httpService.get(ENDPOINTS.companies, params);
}

async function fetchCompany(companyUid) {
  return httpService.get(ENDPOINTS.company(companyUid));
}

async function generateCompanySecretDraft(companyUid) {
  return httpService.post(ENDPOINTS.companySecret(companyUid));
}

async function deleteCompanySecretDraft(companyUid) {
  return httpService.delete(ENDPOINTS.companySecret(companyUid));
}

async function activateCompanySecretDraft(companyUid) {
  return httpService.post(ENDPOINTS.companySecretActivation(companyUid));
}

const CompanyService = {
  fetchCompanies,
  fetchCompany,
  generateCompanySecretDraft,
  deleteCompanySecretDraft,
  activateCompanySecretDraft,
};

export default CompanyService;
