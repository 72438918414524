import { Logger } from '@/utils/logger';
import { IS_PRODUCTION } from '@/config';

export function registerServiceWorker() {
  if (!(IS_PRODUCTION && 'serviceWorker' in navigator)) return;

  Logger.debug('registerServiceWorker');

  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      Logger.info('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      Logger.error('Service Worker registration failed:', error);
    });
}

export function updateServiceWorker() {
  if (!(IS_PRODUCTION && 'serviceWorker' in navigator)) return;

  Logger.debug('updateServiceWorker');

  navigator.serviceWorker
    .getRegistration()
    .then((registration) => {
      if (registration) {
        // Trigger service worker update
        registration.update();
      }
    })
    .catch((error) => {
      Logger.error('Error updating Service Worker:', error);
    });
}
