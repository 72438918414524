import _sortBy from 'lodash.sortby';
import UserService from '@/api/userApi';
import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({
  collaborators: [],
});

const UserSettingsModule = {
  state: getDefaultState(),
  actions: {
    async fetchUserSettings({ commit }) {
      const data = await UserService.fetchUserSettings();
      commit('setUserSettings', data);
      return data;
    },
    async updateCollaborators(_, data) {
      await UserService.updateCollaborators(data);
    },
  },
  getters: {
    collaborators: (state) => {
      return _sortBy(state.collaborators || [], ['email']);
    },
  },
  mutations: {
    setUserSettings(state, { collaborators }) {
      state.collaborators = collaborators;
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
};

export default UserSettingsModule;
