import DocumentService from '@/api/documents';
import { RESET_STORE_MUTATION } from './helper';

export const getDefaultState = () => ({
  isFetchingDocuments: false,
  billingDocumentsPaginated: {
    items: [],
    page: 1,
    count: 0,
    has_prev: false,
    has_next: false,
  },
});

const documentsModule = {
  state: getDefaultState(),
  actions: {
    async fetchBillingDocuments({ commit }, { companyUid, limit, page }) {
      commit('setIsFetchingDocuments', { loading: true });
      const data = await DocumentService.fetchDocuments(companyUid, limit, page);
      commit('setBillingDocuments', { data });
      commit('setIsFetchingDocuments', { loading: false });
      return data;
    },
    async downloadDocument({}, { companyUid, documentUid }) {
      if (!documentUid) throw new Error('missing_document_file_uid');

      await DocumentService.downloadDocument(companyUid, documentUid);
    },
  },
  getters: {
    getDocumentFilesItems: (state) => state.billingDocumentsPaginated.items,
    getDocumentFilesCount: (state) => state.billingDocumentsPaginated.count,
    getDocumentFilesPage: (state) => state.billingDocumentsPaginated.page,
    isFetchingDocuments: (state) => state.isFetchingDocuments,
  },
  mutations: {
    setBillingDocuments(state, { data }) {
      state.billingDocumentsPaginated = data;
    },
    setIsFetchingDocuments(state, { loading }) {
      state.isFetchingDocuments = loading;
    },
    [RESET_STORE_MUTATION](state) {
      Object.assign(state, getDefaultState());
    },
  },
};

export default documentsModule;
