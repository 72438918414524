import * as Sentry from '@sentry/vue';
import { IS_PRODUCTION, VUE_APP_VERSION } from '@/config';

const SENTRY_KEYS = {
  MISSING_I18N_KEY: 'missing_i18n_key',
};

const SENTRY_LEVELS = {
  DEBUG: 'debug',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  FATAL: 'fatal',
};

function initSentry(app, router) {
  if (!IS_PRODUCTION) return;

  Sentry.init({
    app,
    environment: window.location.hostname,
    release: VUE_APP_VERSION,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'jigsaw is not defined',
      // Facebook borked
      'fb_xd_fragment',
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^safari-extension:\/\//i,
      /^webkit-masked-url:\/\//i,
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
  });
}

function configureScope(userPayload) {
  if (!IS_PRODUCTION) return;

  Sentry.configureScope((scope) => {
    scope.setUser(userPayload);
  });
}

/**
 * Send to Sentry a specific message to detect
 * @param {string} messageKey the message key used in sentry
 * @param {string} level the alert level
 * @param {dict} tags more details about the message { key: value }
 * @returns void
 */
function captureMessage(messageKey, level, tags) {
  if (!IS_PRODUCTION) return;

  Sentry.captureMessage(messageKey, (scope) => {
    scope.setLevel(level);
    scope.setTags(tags);
  });
}

export default {
  SENTRY_KEYS,
  SENTRY_LEVELS,
  initSentry,
  configureScope,
  captureMessage,
};
