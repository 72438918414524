import LOCALES from './locales';
import get_states from './states';

export default {
  account: {
    customer_account: 'Customer account',
    customer: 'Customer',
    email: 'Email',
    phone_number: 'Phone number',
    address: 'Address',
    shipping_address: 'Shipping address',
    firstname: 'Firstname',
    lastname: 'Lastname',
  },
  dashboard: {
    merchants: 'Accounts',
    search_globally: 'Search by reference or by keyword (e.g. lastname:dupont)',
    no_merchant_intro: 'You have not configured a payment solution yet',
    merchant_contact_us_help:
      "If you don't have time, <a href='https://pledg.co/contact-us/' class='font-bold' target='_blank'>leave us your contact information</a> and we will do it for you.",
    merchant_select_multiple: 'Select multiple',
    merchant_show_disabled: 'Show disabled',
    disabled: 'disabled',
    show_display_options: 'Show options',
    hide_display_options: 'Hide options',
    merchant_list_empty: 'No payment solution found',
    tabs: {
      merchant_purchases: 'Purchases',
      merchant_transfers: 'Transfers',
      merchant_exports: 'Exported files',
      merchant_settings: 'Settings',
      merchant_collaborators: 'Collaborators',
      merchant_orders: 'Orders',
    },
    select_all: 'Select all ({n})',
    unselect_all: 'Unselect all ({n})',
  },
  errors: {
    try_again: 'Please try again in a few moments.',
    unknown: 'An unknown error occurred.',
    check_form_data: 'Please check your form data',
    cannot_check_purchase_validity: 'Cannot check purchase validity',
    some_fields_are_mandatory: 'Some fields are mandatory',
    please_fill_fields: 'Please fill mandatory fields',
    field_is_empty: 'Field is empty',
    resource_not_allowed: "You don't have the permission to access the requested resource",
    resource_not_found: 'The requested resource cannot be found',
    updating_own_profile_not_allowed: 'Updating your own profile is not permitted.',
    updating_unrelated_user_not_allowed: 'Your are not allowed to update this member',
    b2b_company_not_found: 'The company is not referenced.',
  },
  export: {
    column: {
      uid: 'Reference',
      merchants: 'Payment solutions',
      state: 'State',
      type: 'Type',
      created: 'Date',
    },
    back_to_list: 'Back to list',
    list_empty_items: 'No exported files to display',
    list_empty_items_infos:
      "Here you will find your data exported from the 'Purchases' or 'Transfers' tab",
    list_page_title: 'List of exported files',
    list_page_info:
      'To export a new file, select some merchants in the left sidebar by clicking on them',
    post_page_title: 'Export data',
    post_page_info: 'Select merchants in the left sidebar by clicking on them',
    post_success_title: 'The export is in progress',
    post_success_text: "Exported files can be found in the 'Exported files' tab",
  },
  filters: {
    acceptance_state: 'Acceptance state',
    acceptance_rejection_type: 'Rejection type',
    apply: 'Apply',
    after: 'After',
    amount: 'Amount',
    before: 'Before',
    between: 'Between',
    clear: 'Clear',
    date: 'Date',
    equal: 'Equal',
    less: 'Less than or equal',
    filters: 'Filters',
    more: 'More than or equal',
    state: 'State',
    errors: 'Errors',
    is_purchase_link: 'Is a purchase link?',
    yes: 'Yes',
    no: 'No',
  },
  general: {
    activate: 'Activate',
    cancel: 'Cancel',
    delete: 'Delete',
    copy: 'Copy',
    warning: 'Warning',
    filter: 'Filter',
    next_page: 'Next',
    previous_page: 'Previous',
    search: 'Search',
    export: 'Export',
    close: 'Close',
    edit: 'Edit',
    validate: 'Validate',
    refresh: 'Refresh',
    update_available: 'New version available!',
    payment_solution: 'Payment solution',
    payment_solutions: 'Payment solutions',
    company: 'Company',
    companies: 'Companies',
    copy_successful: 'The text has been copied successfully',
    copy_failed: 'The text cannot be copied',
    limit_reached: 'Limit reached',
    max_merchants_selected:
      'For technical reasons, the maximum number of selectable payment solutions is 100',
    not_found_route: 'The page you requested was not found',
    back_to_app: 'Go back to application',
  },
  documents: {
    title: 'Your documents',
    list_empty_items: 'No billing documents',
    list_empty_items_infos: 'You will find your invoices and credit notes when they are issued.',
    billing: {
      info: 'Invoices & Credit notes',
      type: 'Type',
      reference: 'Reference',
      date: 'Date',
      download: 'Download',
      invoice: 'Invoice',
      credit_note: 'Credit note',
      amount: 'Amount',
      state: 'State',
      due_date: 'Due date',
    },
    tabs: {
      billing: 'Billing',
    },
  },
  key_indicators: {
    title: 'Your performance indicators',
    tabs: {
      gmv: 'GMV',
      acceptance: 'Acceptance',
      risk: 'Risk',
      billing: 'Billing',
    },
  },
  user_settings: {
    title: 'Settings',
    tabs: {
      collaborators: 'Company collaborators',
      companies: 'Companies',
    },
    collaborators: {
      info: 'The following users are part of your company',
      email: 'Email',
      profile: 'Profile',
      disabled: 'Disabled',
      update_collaborator_success: 'The change has been taken into account',
    },
    companies: {
      info: 'Select a company linked to your account',
      empty_list: 'No company found',
    },
  },
  landing: {
    email: 'Email',
    forgot: 'Forgot password?',
    home: 'Welcome',
    home_subtitle: 'Log in to your account',
    login: 'Log in',
    login_subtitle: 'Welcome back ! Enter your credentials to log in to your dashboard.',
    password: 'Password',
    login_invalid_credentials: 'Your credentials appear to be invalid.',
  },
  menu: {
    exports: 'Exports',
    home: 'Dashboard',
    purchase_link: 'Purchase Link',
    new_account: 'Accounts',
    signout: 'Sign out',
    key_indicators: 'Key indicators',
    documents: 'Documents',
    user_settings: 'Settings',
  },
  merchant_form: {
    additional_information: 'Additional information',
    country_code: 'SMS Country Destination',
    css_url: 'CSS URL',
    day: 'None | 1 day | {n} days',
    deferred_type: 'Deferred payment type',
    deferred_delay_in_days: 'Number of deferred days',
    deferred_day_of_next_month: 'Deferred to day of next month',
    installments_fees: '(fixed fees of 0.5%)',
    installments_nb: 'Number of installments',
    installment: 'None | 1 installment | {n} installments',
    down_payment: '{percent} %',
    down_payment_percentage: 'Deposit percentage',
    down_payment_percentage_placeholder: 'Between 0 and 100',
    down_payment_balance_day: '1 day | {n} days',
    down_payment_balance_max_days: 'Max number of days before balance capture',
    down_payment_balance_max_days_placeholder: 'Between 1 and 270',
    language: 'Language',
    main_information: 'General information',
    payment_information: 'Payment information',
    name: 'Company name',
    uid: 'UID',
    payment_method_params: {
      iban: 'IBAN',
      bic: 'BIC',
    },
    info: 'Short description',
    picture_url: 'Logo URL',
    picture_url_empty: 'No picture supplied',
    picture_url_dropzone: 'Click or drop a file to upload a picture',
    picture_url_invalid: 'This image is not valid. Please select another one.',
    payment_type: 'Payment type',
    requested_opt: 'Consent of user to share his data with Pledg',
    requested_opt__disabled: 'Disabled',
    requested_opt__in: 'Opt-in',
    requested_opt__out: 'Opt-out',
    site_url: 'Website URL',
    secret: 'Secret key',
    reveal_secret: 'Click to reveal secret key',
    submit_update: 'Update',
    timezone: 'Timezone',
    update_successful: 'The settings have been updated successfully',
    upload_picture: 'Click here to upload a new logo',
  },
  company_form: {
    main_information: 'General information',
    technical_information: 'Technical information',
    name: 'Company name',
    uid: 'UID',
    secret: 'Secret',
    secret_draft: 'New secret',
    generate_secret_draft: 'Generate a new secret',
    generate_secret_draft_explanation:
      'Generate a new secret by clicking on the button above. It will be activated after validation, and will replace the current secret.',
    activate_secret_information:
      'Activating the new secret will definitely delete the current secret. Are you sure you want to continue?',
    secret_activation_successful: 'The new secret has been activated successfully',
  },
  payment_types: {
    DEFERRED: 'Deferred',
    ROLLING_DEFERRED: 'Deferred (rolling preauthorization)',
    INSTALLMENT: 'Installment',
    SPLIT: 'Split',
    REDIRECTION: 'Redirection',
    DOWN_PAYMENT: 'Down payment',
  },
  purchases: {
    details: 'Purchase details',
    general_details: 'General information',
    empty: 'No purchase to display',
    fees: 'Fees',
    purchases: 'Purchases',
    details_uid: 'UID',
    order_uid: 'Order UID',
    customer_dashboard_url: 'Customer dashboard',
    in_stock: 'In stock',
    out_of_stock: 'Out of stock',
  },
  quicksearch: {
    suggestion_label: 'Suggested filters',
    results_label: 'Results',
    filters: {
      purchase: 'purchase UID',
      order: 'order UID',
      transfer: 'transfer/debit UID',
      email: 'customer email',
      ref: 'purchase reference',
      phone: 'customer phone number',
      firstname: 'customer first name',
      lastname: 'customer last name',
      statement: 'statement descriptor',
      title: 'purchase title',
    },
  },
  shares: {
    installments: 'Installments',
    split: 'Participants',
    expecting_transfer: 'Expecting transfer',
    ancv_share: 'Chèque-Vacances',
  },
  refunds: {
    amount: 'Amount',
    status: 'Status',
    refunds: 'Refunds',
    refund: 'Refund',
    date: 'Date',
    close_refund_form: 'Close',
    cancel_refund_form: 'Cancel refund',
    request_a_refund: 'Request a refund',
    error_refund_request: 'An error occurred in the refund request',
    request_is_sent: 'The request has been sent, it will be processed as soon as possible.',
    request_a_refund_details: 'Please enter an amount (max: {amount} {currency})',
    requested_refunds: 'Requested refunds',
    no_refund_for_purchase_yet: 'There is no refund for this purchase yet',
    no_refund_request: 'No refund request yet',
    partial_refund: '{amount} has been refunded',
    partial_not_charge: 'Only {amount} will be captured',
    full_refund: 'This payment has been fully refunded by {amount}',
    full_not_charge: 'This payment has been fully deduced',
    debited: '{amount} has been debited',
    not_charged: '{amount} has been deducted',
    reference: 'Reference',
    initialized: 'Initialized',
    refused_refund: 'The amount to refund is greater than the maximum possible',
    duplicate_refund: 'Please wait a minute and try again',
  },
  purchases_table: {
    address: 'Address',
    amount: 'Amount',
    availability: 'Availability',
    created: 'Date',
    customer: 'Customer',
    email: 'Email',
    name: 'Name',
    phone_number: 'Phone number',
    reference: 'Reference',
    state: 'State',
    view_detail: 'View purchase detail',
    virtual_card: 'Virtual card',
    transfer: 'Transfer',
    debit: 'Debit',
    error_message: 'Error',
    refund_operations: 'Refunds',
    deferred_payment_date: 'Deferred payment date',
    balance_payment_date: 'Balance payment date',
    no_account: 'No account',
    error: 'Error',
    purchase_link: 'Purchase link',
    essentiel_contract_id: 'Essentiel contract ID',
    open_stripe_documentation: 'Open Stripe documentation',
    validation_error: 'Validation error',
    payment_error: 'Payment error',
    anonymized_account: 'Anonymized account',
    anonymized_data_info: 'Data anonymized in accordance with regulations',
  },
  validations: {
    email: 'The field must be a valid email address.',
    required: 'The field is required.',
    min: 'The field must contain at least {_param_1_} characters.',
    max: 'The field must contain at most {_param_1_} characters.',
    min_value: 'The field must be greater than {_param_1_}',
    alpha_dash_space:
      'The field must contain only alpha-numeric characters, dashes or underscores.',
    limited_ascii:
      "The field must contain only alpha-numeric characters, dash, underscore, dot, semicolon, space and {'@'}.",
    url: 'Must be a valid URL (http(s)://website.com/...)',
    percentage: 'Must be a valid percentage (number between 0 and 100).',
    delay: 'Must be a valid number of days (number between 1 and 270).',
    css: 'The field must contain a valid css file',
    no_spaces: 'The field must not contain any spaces',
    ean13: 'The field must be in EAN-13 format',
    numeric: 'The field must contain only numeric characters',
  },
  collaborators: {
    add_collaborator: 'Add a new collaborator',
    n_collaborators: 'No collaborator | {n} collaborator | {n} collaborators',
    name: 'Collaborators',
    delete_collaborator: 'Delete collaborator',
    error_add_default: 'Impossible to create a new collaborator',
    error_delete_default: 'Impossible to delete this collaborator',
    error_at_least_one_collaborator: 'The merchant must have at least one collaborator',
    error_collaborator_exists: 'This collaborator already exists',
    error_merchant_role_not_found: 'Please create a role for this merchant first',
    error_self_remove_not_allowed: 'Cannot delete your own ID',
    error_user_not_found: 'This user does not exist',
    error_user_role_exists: 'The user already has a role',
  },
  transfers_table: {
    amount: 'Amount',
    state: 'Status',
    date: 'Date',
    reference: 'Reference',
    purchases: 'Linked Purchases',
    search: 'Search by reference or by keyword (e.g. statement:TR123456)',
  },
  transfers: {
    transfer_details: 'Transfer details',
    debit_details: 'Debit details',
    empty: 'No transfer to display',
    transfers: 'Transfers',
  },
  purchase_links: {
    select_merchant: 'Please select a payment solution in the list',
    disabled_merchant: 'You cannot generate purchase links on disabled configuration',
    enter_a_valid_amount: 'Please enter a valid amount to simulate the payment schedule',
    date: 'Date',
    create_link: 'Create a purchase link',
    purchase_parameters: 'Purchase parameters',
    amount: 'Amount',
    total_amount: 'Total amount',
    including_fees: 'Including fees',
    currency: 'Currency',
    title: 'Title',
    subtitle: 'Subtitle',
    notification_url: 'Payment notification email or URL',
    reference: 'Reference',
    buyer_informations: 'Buyer informations',
    b2b_company_name: 'Company name',
    b2b_company_national_id: 'SIREN',
    b2b_insurable_info_section: 'Insurer information',
    b2b_max_outstanding_info: 'For the selected payment solution, maximum amount allowed:',
    b2b_max_outstanding_cross_purchases_info: 'and in multiple purchases, up to:',
    b2b_max_outstanding_waiting_for_siren:
      'Please enter the SIREN to display the maximum amount allowed for the selected payment solution.',
    civility: 'Civility',
    mister: 'Mr',
    miss: 'Miss',
    firstname: 'First name',
    lastname: 'Last name',
    birthlastname: 'Birth last name',
    nationality: 'Nationality',
    email: 'Email',
    phone_number: 'Phone number',
    use_area_code: 'Please add the International Calling Code if necessary',
    address: 'Address',
    street_number_name: 'Street number and name',
    zip_code: 'Zip code',
    city: 'City',
    country: 'Country',
    options: 'Options',
    options_user_interface: 'User interface',
    options_schedule: 'Schedule',
    options_merchant: 'Merchant',
    options_set_second_installment_date: 'Set a second installment date',
    secondInstallmentPaymentDate: 'Second Installment date',
    options_set_notification_url: 'Set a notification email/URL',
    redirect_url: 'End of funnel success redirection url',
    cancel_url: 'End of funnel failure redirection url',
    showCloseButton: 'Display a close button',
    language: 'Language',
    cta_generate_purchase_link: 'Generate a purchase link',
    here_is_your_link: 'Here is your link: ',
    link_ready: 'Your link is ready!',
    link_has_been_sent_by_email: 'The following link has been sent by email',
    link_has_been_sent_by_sms: 'The following link has been sent by sms',
    link_has_been_sent: 'The following link has been sent by email and sms',
    option_send_by_email: 'Send link by email',
    option_send_by_sms: 'Send link by SMS',
    cta_generate_and_send_email_purchase_link: 'Send generated link by email',
    cta_generate_and_send_purchase_link: 'Send generated link by email and sms',
    cta_generate_and_send_sms_purchase_link: 'Send generated link by sms',
    invalid_purchase: 'Purchase is not valid',
    valid_purchase: 'Purchase is valid',
    check_this_purchase: 'Check this purchase',
    birth_place: 'Birthplace',
    birth_date: 'Birthdate',
    birth_city: 'Birth city',
    birth_zipcode: 'Birth zipcode',
    birth_country: 'Birth country',
    search_city_placeholder: 'Search a birthplace',
    search_address_placeholder: 'Search an address',
    close_modal: 'Close',
    simulate_payment_schedule: 'Payment schedule',
    fill_form: 'Please complete all required fields of the form',
    date_from_options: 'Second installment date set in options',
    product_ean13_reference: 'EAN 13 product reference',
    designation: 'Designation',
    cart_information: 'Cart information',
    enable_merchant_payment_notification_url: 'Receive a notification',
    loan_details: 'Proposal details',
    purchase_amount: 'Purchase amount',
    loan_duration: 'Number of installments',
    months: ' months',
    first_installments_amount: '{installments_nb} installments of',
    last_installment_amount: '1 last adjusted installment of',
    delay_in_months: 'Installments start in',
    no_schedule_available:
      'No financing available for this payment facility with the parameters used.',
    taeg: 'APR',
  },
  languages: {
    fr_FR: 'French',
    en_GB: 'English',
    de_DE: 'German',
    nl_NL: 'Dutch',
    it_IT: 'Italian',
    es_ES: 'Spanish',
    pt_PT: 'Portuguese',
  },
  currencies: {
    poundsterling: 'Pound sterling',
    euro: 'Euro',
  },
  profiles: {
    READER: 'Reader',
    SELLER: 'Seller',
    MANAGER: 'Manager',
    ACCOUNTANT: 'Accountant',
    ADMIN: 'Admin',
  },
  countries: {
    luxembourg: 'Luxembourg',
    germany: 'Germany',
    spain: 'Spain',
    france: 'France',
    netherlands: 'Netherlands',
    unitedkingdom: 'United Kingdom',
    austria: 'Austria',
    italy: 'Italy',
    belgium: 'Belgium',
    switzerland: 'Switzerland',
    portugal: 'Portugal',
  },
  orders: {
    details: 'Order details',
    informations: 'Informations',
    empty: 'No order to display',
    fees: 'Fees',
    orders: 'Orders',
    details_uid: 'UID',
    table: {
      address: 'Address',
      amount: 'Amount',
      created: 'Date',
      customer: 'Customer',
      email: 'Email',
      name: 'Name',
      phone_number: 'Phone number',
      reference: 'Reference',
      state: 'State',
      view_detail: 'View order detail',
      error_message: 'Error',
      no_account: 'No account',
      error: 'Error',
      open_stripe_documentation: 'Open Stripe documentation',
      validation_error: 'Validation error',
      payment_error: 'Payment error',
    },
  },
  misc_order: {
    build_pdf: 'Create a miscellaneous order',
    build_success: 'Miscellaneous order created with success',
    build_error: 'An error occured during the creation of the miscellaneous order: {error}',
    label: 'Miscellaneous order',
    pdf_name: 'Miscellaneous order - {date} - {firstname} {lastname} - {reference}.pdf',
    purchase_information: 'Purchase information',
    amount_financed: 'Amount financed',
    datetime: 'Date time',
    articles: 'Product references',
    purchase_summary: 'Purchase of {amountCurrency} the {date}',
    reserved_frame: 'Frame reserved for the seller',
  },
  product_information: {
    deferred: {
      delay_in_days: 'Deferred by {days} day(s)',
      day_of_next_month: '{days} of next month',
    },
    installment: '{nb}x',
  },
  states: {
    unknown: 'Unknown',
    ...get_states(LOCALES.EN),
  },
  acceptance_states: {
    unknown: 'Unknown',
    purchase: {
      ACCEPTED: 'Accepted',
      REJECTED: 'Rejected',
      ABANDONED: 'Abandoned',
      NOT_INITIATED: 'Not initiated',
      INITIATED: 'Initiated',
    },
  },
  acceptance_rejection_types: {
    unknown: 'Unknown',
    purchase: {
      TECHNICAL_ERROR: 'Technical error',
      PLEDG_REFUSAL: 'Pledg refusal',
      NOT_ELIGIBLE: 'Not eligible',
      CUSTOMER_BANK_REFUSAL: 'Customer bank refusal',
    },
  },
  locales: {
    paris_time: 'Paris time',
  },
  essentiel: {
    funding: 'Funding',
    trigger_funding: 'Trigger funding',
    funding_available: 'Funding has not yet been triggered for this payment',
    funding_initiated: 'Funding for this payment has already been initiated',
    funding_failed: 'Funding for this payment has failed',
    funding_success: 'Funding for this payment has been successful',
    release: 'Cancellation',
    trigger_release: 'Trigger cancellation',
    release_available: 'Cancellation has not yet been triggered for this payment',
    release_initiated: 'Cancellation for this payment has already been initiated',
    release_available_contact:
      "Please contact {'gestionpartenaires-psp@ca-cf.fr'} to trigger a cancellation for this payment",
    release_success: 'Cancellation for this payment has been successful',
    release_failed: 'Cancellation for this payment has failed',
    release_confirmation: 'Are you sure you want to cancel this payment?',
    release_confirmation_button: 'Confirm',
  },
};
