import { Form, Field, ErrorMessage, configure, defineRule } from 'vee-validate';
import { required, numeric, email, min, min_value, max } from '@vee-validate/rules';
import { isEan13Reference } from '@/helpers/ean';
import i18n from './i18n';

const getParamsAt = (context, index) => {
  const { params } = context.rule;
  if (!params?.length || params.length < index) return;
  return params.at(index);
};

configure({
  generateMessage: (context) => {
    const ruleData = {
      _value_: context.value,
      _param_1_: getParamsAt(context, 0),
      _param_2_: getParamsAt(context, 1),
    };
    return i18n.global.t(`validations.${context.rule?.name}`, ruleData);
  },
});

export function configureRules() {
  defineRule('required', required);
  defineRule('email', email);
  defineRule('min', min);
  defineRule('min_value', min_value);
  defineRule('max', max);
  defineRule('numeric', numeric);

  // Validates field is composed of alphanumerical values, dashes, underscores or spaces
  defineRule('alpha_dash_space', (value) => RegExp('^[a-zA-Z0-9-_ ]+$').test(value));
  // Validate field is composed of ASCII characters only
  defineRule(
    'limited_ascii',
    (value) => value.trim().length > 0 && RegExp('^[a-zA-Z0-9-_@;. ]+$').test(value),
  );
  // Validate URL
  defineRule('url', (value) => {
    try {
      if (!value) return true;

      const url = new URL(value);
      if (url) {
        return true;
      }
      return false;
    } catch (_) {
      return false;
    }
  });
  defineRule('css', (value) => {
    if (!value) return true;
    return RegExp('css$').test(value);
  });
  defineRule('no_spaces', (value) => value?.indexOf(' ') === -1);
  // Validate percentage
  defineRule('percentage', (value) => {
    const isNumber = RegExp('^[0-9]+$').test(value);
    if (isNumber) {
      return value >= 0 && value <= 100;
    }
    return false;
  });

  // Validate delay
  defineRule('delay', (value) => {
    const isNumber = RegExp('^[0-9]+$').test(value);
    if (isNumber) {
      return value >= 1 && value <= 270;
    }
    return false;
  });

  // validate ean13 reference
  defineRule('ean13', isEan13Reference);
}

function initVeeValidate(app) {
  app.component('VVField', Field);
  app.component('VVForm', Form);
  app.component('VVErrorMessage', ErrorMessage);
  configureRules();
}
export default initVeeValidate;
