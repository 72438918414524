import { createI18n } from 'vue-i18n';
import { VUE_APP_I18N_LOCALE, VUE_APP_I18N_FALLBACK_LOCALE } from '@/config';
import LOCALES from '@/locales/locales';
import { Logger } from '../utils/logger';

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const messages = {};
  const locale_keys = Object.values(LOCALES);
  locale_keys.forEach((key) => {
    messages[key] = locales(`./${key}.js`).default;

    Logger.debug({
      id: 'i18n-load-messages',
      locale: key,
      messages: messages[key],
    });
  });
  return messages;
}

export const numberFormats = {
  en: {
    decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    currency: {
      style: 'currency',
      currencyDisplay: 'symbol',
    },
  },
  fr: {
    decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    currency: {
      style: 'currency',
      currencyDisplay: 'symbol',
    },
  },
};

/* XXX: Note on navigator.language.splice(0, 2).
 * vue i18n $te does not check for fallback values so we can't use dialect or local specifities
 * (i.e. en-us) coming from navigator.language
 * https://github.com/kazupon/vue-i18n/issues/971
 */
const getLocale = (inputLocale) => {
  const locale = (inputLocale || '').slice(0, 2);
  return Object.values(LOCALES).includes(locale) ? locale : undefined;
};

const locale =
  getLocale(localStorage.locale) || getLocale(navigator.language) || VUE_APP_I18N_LOCALE || 'en';

Logger.info({ id: 'vue-i18n-initialization', locale });

const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  warnHtmlInMessage: 'off', // disabling the Detected HTML in message
  numberFormats,
});

export default i18n;
