/** **********************
 * Find the current theme to be applied based on the URL domain name
 */
import { THEMES, THEME_CACF, THEME_BY_OPERATOR } from '@/constants';

function themeMixin() {
  return {
    computed: {
      themeMixin_userOperator() {
        return this.$auth.user()?.operator;
      },
      themeMixin_theme() {
        return (
          (this.themeMixin_userOperator && THEME_BY_OPERATOR[this.themeMixin_userOperator]) ||
          THEMES.find((theme) => window.location.hostname.endsWith(theme.DOMAIN_NAME)) ||
          THEME_CACF
        );
      },
      themeMixin_className() {
        return this.themeMixin_theme.CLASS_NAME;
      },
      themeMixin_logoFilename() {
        return this.themeMixin_theme.LOGO_FILENAME;
      },
      themeMixin_logoAlt() {
        return this.themeMixin_theme.LOGO_ALT;
      },
      themeMixin_menuIcon() {
        return this.themeMixin_theme.MENU_ICON;
      },
      themeMixin_cardGradientClasses() {
        return this.themeMixin_theme.CARD_GRADIENT_CLASSES;
      },
      themeMixin_faviconLocation() {
        return `${window.location.origin}/${this.themeMixin_theme.PUBLIC_FOLDER}/favicon.ico`;
      },
      themeMixin_title() {
        return this.themeMixin_theme.TITLE;
      },
    },
  };
}

export default themeMixin();
