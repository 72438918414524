import { createStore, createLogger } from 'vuex';
import { IS_PRODUCTION } from '@/config';
import collaborators from './modules/collaborators';
import merchants from './modules/merchants';
import purchases from './modules/purchases';
import transfers from './modules/transfers';
import orders from './modules/orders';
import exportData from './modules/exportData';
import filters from './modules/filters';
import documents from './modules/documents';
import userSettings from './modules/userSettings';
import companies from './modules/companies';

const plugins = [];

if (!IS_PRODUCTION) {
  plugins.push(createLogger());
}

export const rootGetters = {};

const store = createStore({
  modules: {
    collaborators,
    exportData,
    filters,
    merchants,
    purchases,
    transfers,
    orders,
    userSettings,
    companies,
    documents,
  },
  strict: !IS_PRODUCTION,
  state: {
    merchant_edit: Boolean(Number(process.env.VUE_APP_MERCHANT_EDITION)),
  },
  getters: rootGetters,
  plugins,
});
export default store;
