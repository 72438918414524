import { downloadCSV } from '@/helpers/downloadFile';
import httpService from './httpService';

const ENDPOINTS = {
  fetch_exported_files: '/users/me/merchants/export',
  export_transfers: '/users/me/merchants/transfers/export',
  export_purchases: '/users/me/merchants/purchases/export',
  download_exported_file: (uid) => `/users/me/merchants/export/${uid}/download`,
};

/**
 * Fetch list of exported files from server
 * @param {*} queryParams : limit, page
 * @returns {Promise<Paginated<ExportFiles>>} : { items, page, count, has_prev, has_next }
 */
async function getExportFilesList({ limit, page = 1, merchantUids }) {
  const params = {
    merchant_uids: JSON.stringify(merchantUids),
    limit,
    page,
  };
  return httpService.get(ENDPOINTS.fetch_exported_files, params);
}

/**
 * Ask for an export of transfers
 * @param {*} queryParams the filter parameters
 */
async function exportTransfers(merchantUids, queryParams = {}, filters = []) {
  if (!merchantUids) throw new Error('missing_merchant_uids');

  return httpService.post(
    ENDPOINTS.export_transfers,
    null,
    {
      merchant_uids: merchantUids,
      q: queryParams,
      ...Object.fromEntries(filters),
    },
    { responseType: 'blob' },
  );
}

/**
 * Ask for an export of purchases
 * @param {*} queryParams the filter parameters
 */
async function exportPurchases(merchantUids, queryParams = {}, filters = []) {
  if (!merchantUids) throw new Error('missing_merchant_uids');

  return httpService.post(
    ENDPOINTS.export_purchases,
    null,
    {
      merchant_uids: merchantUids,
      q: queryParams,
      ...Object.fromEntries(filters),
    },
    { responseType: 'blob' },
  );
}

async function downloadExportFile(uid) {
  if (!uid) throw new Error('missing_export_file_uid');

  try {
    const response = await httpService.get(
      ENDPOINTS.download_exported_file(uid),
      null,
      null,
      null,
      {
        redirectOn403: true,
        returnFullResponse: true,
      },
    );
    const filename = response.headers['content-disposition']
      ? response.headers['content-disposition'].split('___')[1]
      : null;
    downloadCSV(response.data, filename);
  } catch (e) {
    throw e.response.data.error;
  }
}

const ExportService = {
  getExportFilesList,
  exportPurchases,
  exportTransfers,
  downloadExportFile,
};

export default ExportService;
