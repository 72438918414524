import httpService from './httpService';

export const ENDPOINTS = {
  merchants: '/users/me/merchants',
  merchant: (merchantUid) => `/users/me/merchant/${merchantUid}`,
  payment_types: '/merchants/payment_types',
  resources: '/users/me/merchants/resources',
  simulate_payment_schedule: (merchantUid) =>
    `/users/me/merchants/${merchantUid}/simulate_payment_schedule`,
  max_outstanding: (merchantUid) => `/users/me/merchants/${merchantUid}/max_outstanding`,
};

async function fetchMerchants() {
  return httpService.get(ENDPOINTS.merchants);
}

async function fetchMerchant(merchantUid) {
  return httpService.get(ENDPOINTS.merchant(merchantUid));
}

async function updateMerchant(merchantUid, data) {
  return httpService.patch(ENDPOINTS.merchant(merchantUid), null, data);
}

async function fetchPaymentTypes() {
  return httpService.get(ENDPOINTS.payment_types);
}

async function simulatePaymentSchedule(merchantUid, data) {
  return httpService.post(ENDPOINTS.simulate_payment_schedule(merchantUid), null, data);
}

async function fetchMaxOutstanding(merchantUid, data) {
  return httpService.get(ENDPOINTS.max_outstanding(merchantUid), data);
}

const MerchantService = {
  fetchMerchants,
  fetchMerchant,
  updateMerchant,
  fetchPaymentTypes,
  simulatePaymentSchedule,
  fetchMaxOutstanding,
};

export default MerchantService;
